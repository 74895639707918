<template>
  <LogoutButton v-if="$msal.isSignedIn" />
  <LoginButton v-else />
</template>

<script>
import LoginButton from '@/components/LoginButton';
import LogoutButton from '@/components/LogoutButton';

export default {
  components: {
    LoginButton,
    LogoutButton,
  },
};
</script>

<style></style>
