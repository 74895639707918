import axios from 'axios';
import { msal } from '@/plugins/msal';

const instance = axios.create({
  baseURL: 'https://moda-apim.azure-api.net/portal',
});

instance.interceptors.request.use(
  async (config) => {
    const token = await msal.getToken({
      scopes: [
        'https://motoropdeaanhanger.onmicrosoft.com/portal-api/customers.read',
        'https://motoropdeaanhanger.onmicrosoft.com/portal-api/customers.write',
      ],
    });
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
