<template>
  <div>
    <v-navigation-drawer v-model="drawer" :clipped="clipped" app>
      <NavigationList />
    </v-navigation-drawer>
    <v-app-bar :clipped-left="clipped" app color="primary" dark elevation="1">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"><v-icon>mdi-hamburger</v-icon></v-app-bar-nav-icon>
      <v-toolbar-title>MODA</v-toolbar-title>
      <v-spacer></v-spacer>
      <span v-if="$msal.account" class="mr-4 hidden-xs-only">{{ $msal.account.name }}</span>
      <AuthButton />
    </v-app-bar>
  </div>
</template>

<script>
import NavigationList from '@/components/NavigationList';
import AuthButton from '@/components/AuthButton';

export default {
  name: 'BaseLayout',
  components: {
    NavigationList,
    AuthButton,
  },
  data() {
    return {
      drawer: null,
    };
  },
  computed: {
    clipped() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
  },
};
</script>
