import portal from '@/axios/portal';

export default {
  getAllCustomers() {
    return portal.get('/customers');
  },
  createNewCustomer(body) {
    return portal.post('/customers', body);
  },
  deleteCustomer(customer) {
    return portal.delete(`/customers/${customer.id}`);
  },
};
