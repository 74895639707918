import { msal } from '@/plugins/msal';

const authGuard = (to, from, next) => {
  const guardAction = () => {
    if (msal.isSignedIn) next();
    else msal.signIn({ redirectStartPage: to.fullPath });
  };

  if (msal.isLoaded) guardAction();

  msal.$watch('isLoaded', (isLoaded) => {
    if (isLoaded) guardAction();
  });
};

export default authGuard;
