<template>
  <v-app>
    <BaseLayout />
    <v-main class="grey lighten-4">
      <v-container class="pa-4">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import BaseLayout from '@/components/BaseLayout';

export default {
  name: 'App',
  components: {
    BaseLayout,
  },
};
</script>
