<template>
  <v-list nav>
    <v-list-item-group color="primary" class="ml-1" active-class="border-left font-weight-bold">
      <v-list-item v-for="item in items" :key="item.title" :to="item.route" :exact="item.route.name === 'Home'">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'NavigationList',
  data() {
    return {
      items: [
        { title: 'Home', icon: 'mdi-home-variant-outline', route: { name: 'Home' } },
        { title: 'Customers', icon: 'mdi-account-tie', route: { name: 'Customers' } },
      ],
    };
  },
};
</script>

<style scoped>
.border-left {
  border-left: 5px solid;
  margin-left: -5px;
}
</style>
