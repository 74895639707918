import Vue from 'vue';
import Vuex from 'vuex';
import customers from '@/store/modules/customers';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { customers },
  strict: process.env.NODE_ENV === 'development',
});
