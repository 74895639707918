import portalService from '@/services/portalService';

export default {
  namespaced: true,
  state: {
    customers: [],
    loading: false,
  },
  mutations: {
    SET_CUSTOMERS(state, customers) {
      state.customers = customers;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    DELETE_CUSTOMER(state, customer) {
      const customers = state.customers.filter((c) => c.id !== customer.id);
      state.customers = customers;
    },
  },
  actions: {
    getAllCustomers({ commit }) {
      commit('SET_LOADING', true);
      portalService
        .getAllCustomers()
        .then((response) => commit('SET_CUSTOMERS', response.data))
        .catch((error) => console.error(error))
        .finally(() => commit('SET_LOADING', false));
    },
    deleteCustomer({ commit }, customer) {
      portalService
        .deleteCustomer(customer)
        .then(() => commit('DELETE_CUSTOMER', customer))
        .catch((error) => console.error(error));
    },
  },
  getters: {},
};
