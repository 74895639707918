import vueInstance from 'vue';
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';

const config = {
  clientConfig: {
    auth: {
      clientId: 'c35c9082-00c2-4bec-85db-ad76a512397f',
      authority: 'https://motoropdeaanhanger.b2clogin.com/motoropdeaanhanger.onmicrosoft.com/B2C_1_signin1',
      knownAuthorities: ['motoropdeaanhanger.b2clogin.com'],
      redirectUri: process.env.VUE_APP_REDIRECT_URI,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  },
  loginRequest: {
    scopes: ['openid', 'profile'],
  },
};

export const msal = new vueInstance({
  data() {
    return {
      msal: new PublicClientApplication(config.clientConfig),
      account: null,
      isLoaded: false,
    };
  },
  async created() {
    const response = await this.msal.handleRedirectPromise();
    if (response) {
      console.log('handle login redirect', response);
      this.account = response.account;
    } else {
      console.log('handle page load/refresh');
      this.selectAccount();
    }
    this.isLoaded = true;
  },
  methods: {
    signIn(c) {
      this.msal.loginRedirect({ ...config.loginRequest, ...c });
    },
    signOut() {
      this.msal.logoutRedirect({ postLogoutRedirectUri: '/' });
    },
    selectAccount() {
      const accounts = this.msal.getAllAccounts();
      if (accounts.length < 1) return;
      if (accounts.length === 1) {
        this.account = accounts[0];
        return;
      }
      this.signOut();
    },
    getToken(request) {
      console.log('get access token');
      //request.account = this.msal.getAccountByHomeId(this.account.homeAccountId);
      request.account = this.account;
      return this.msal
        .acquireTokenSilent(request)
        .then((response) => response.accessToken)
        .catch((error) => {
          console.log('failed to aquire token silent');
          if (error instanceof InteractionRequiredAuthError) {
            this.msal.acquireTokenRedirect(request);
          } else {
            throw error;
          }
        });
    },
  },
  computed: {
    isSignedIn() {
      return !!this.account;
    },
  },
});

export default {
  install(Vue) {
    Vue.prototype.$msal = msal;
  },
};
